import React, { Fragment } from "react";
import './css/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import AppRoutes from "./AppRoutes";
// import { BrowserRouter } from "react-router-dom";
const BrowserRouter = require("react-router-dom").BrowserRouter;
function App() {
  return (
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
  );
}

export default App;
